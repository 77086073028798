import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtemaua.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3529401',
  schema: 'pbi_dte_maua',
  groupId: '8b57c6be-27a1-41b1-af2d-82252dec6e55',
  analiseGeral: '11ff458f-0716-4764-ba41-12249fde67b6',
  analiseUsuarios: '9f4aacff-ad46-4e87-ba72-87a019518bbd',
  analiseComunicados: 'c396dc62-8e97-4007-9d2a-eab1dfc17167',
  analiseProcurador: '7709016a-0f18-4d6c-b189-b9a0113a2c3b',
  analiseEventos: '7b628211-9aa2-4145-aa2f-b1baf20b567c',
  analiseContribuintes: '6a9478e9-19e5-4d60-a7b7-e1d7dc0247d2'
};
